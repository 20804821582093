import React from "react";
import PropTypes from "prop-types";
import Image from "gatsby-image";
import Layout from "../components/Layout";
import Link from "../components/Link";

const WorkTemplate = ({ pageContext, location }) => {
  const { data: templateData, allFlexible } = pageContext;

  return (
    <Layout seoTitle="Preview" seoDescription="Preview" location={location}>
      <div className="pt-hh px-ogs pb-ogs">
        <h2 className="my-gs text-2xl lg:text-3xl xl:text-4xl leading-tight lg:leading-snug">
          Projects
        </h2>
        <div className="flex flex-wrap -ml-gs -mt-gs">
          {templateData.map((item, index) => {
            const { data, uid } = item.node;
            return (
              <div key={index} className="w-1/2 md:w-1/3 pl-gs pt-gs">
                <Link className="block relative" to={`/preview/${uid}/`}>
                  {data.hero_image.fluid ? (
                    <Image
                      placeholderStyle={{ display: "none" }}
                      fluid={{
                        ...data.hero_image.fluid,
                        aspectRatio: 1.5
                      }}
                      loading="eager"
                    />
                  ) : (
                    <div
                      className="bg-black"
                      style={{ paddingTop: "66.6667%" }}
                    />
                  )}
                  {data.title && (
                    <React.Fragment>
                      <div className="absolute inset-x-0 bottom-0 h-1/4 bg-gradient" />
                      <div className="absolute bottom-0 inset-x-0 p-6">
                        <h3 className="text-base md:text-lg uppercase lg:text-xl text-white leading-tight">
                          {data.title}
                        </h3>
                      </div>
                    </React.Fragment>
                  )}
                </Link>
              </div>
            );
          })}
        </div>
      </div>

      <div className="pt-hh px-ogs pb-ogs">
        <h2 className="my-gs text-2xl lg:text-3xl xl:text-4xl leading-tight lg:leading-snug">
          Pages
        </h2>
        <div className="flex flex-wrap -ml-gs -mt-gs">
          {allFlexible.map((item, index) => {
            const { data, uid } = item.node;
            return (
              <div key={index} className="w-1/2 md:w-1/3 pl-gs pt-gs">
                <Link className="block relative" to={`/preview/${uid}/`}>
                  {data.hero_image.fluid ? (
                    <Image
                      placeholderStyle={{ display: "none" }}
                      fluid={{
                        ...data.hero_image.fluid,
                        aspectRatio: 1.5
                      }}
                      loading="eager"
                    />
                  ) : (
                    <div
                      className="bg-black"
                      style={{ paddingTop: "66.6667%" }}
                    />
                  )}
                  {data.title && (
                    <React.Fragment>
                      <div className="absolute inset-x-0 bottom-0 h-1/4 bg-gradient" />
                      <div className="absolute bottom-0 inset-x-0 p-6">
                        <h3 className="text-base md:text-lg uppercase lg:text-xl text-white leading-tight">
                          {data.title}
                        </h3>
                      </div>
                    </React.Fragment>
                  )}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

WorkTemplate.propTypes = {
  location: PropTypes.object.isRequired
};

export default WorkTemplate;
